

// const webServiceBaseUrl = "http://localhost:51585/api/";
const webServiceBaseUrl = "https://api.teknolabatmserver.com/api/";

// FetchApiGetwithUrl(webServiceUrl, SuccessCallbackFunction, ErrorCallbackFunction) {

//     var urlParameters = jQuery.param(data);
//     var urlData = '?' + urlParameters;       

//     fetch(webServiceUrl + urlData)
//         .then((response) => response.json())
//         .then(kullaniciGirisiResultModel => {
//             SuccessCallbackFunction(data);
//             console.log(kullaniciGirisiResultModel);

//             this.lokasyonlarSayfasinaGit(kullaniciGirisiResultModel);
//         })

//         .catch(function (error) {

//             ErrorCallbackFunction(error);
//         });
// };

export const FetchApiGet = (webServiceMethod, Parameters, SuccessCallbackFunction, ErrorCallbackFunction) => {

    const webServiceUrl = webServiceBaseUrl + webServiceMethod;
    const urlParameters = '?' + new URLSearchParams(Parameters);
    const webServiceUrlWithParameters = webServiceUrl + urlParameters;
    //const urlData = '?' + urlParameters;

    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + sessionStorage.getItem('Token') 
        }        
    };

    fetch(webServiceUrlWithParameters, options)
        .then((response) => response.json())
        .then(data => {
            SuccessCallbackFunction(data);
        })
        .catch( (error) => {
            ErrorCallbackFunction(error);
        });
};

export const FetchApiPost = (webServiceMethod, data, SuccessCallbackFunction, ErrorCallbackFunction) => {

    const webServiceUrl = webServiceBaseUrl + webServiceMethod;

    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + sessionStorage.getItem('Token') 
        },
        body: JSON.stringify(data)
    };

    fetch(webServiceUrl, options)
        .then((response) => response.json())
        .then(data => {
            SuccessCallbackFunction(data);
        })
        .catch( (error) => {
            ErrorCallbackFunction(error);
        });

    // fetch(webServiceUrl, options)
    //     .then((response) => response.json())
    //     .then(function (data) {

    //         SuccessCallbackFunction(data);
    //     })
    //     .catch(function (error) {

    //         ErrorCallbackFunction(error);
    //     });
}

// FetchApiFilePost(webServiceUrl, data, SuccessCallbackFunction, ErrorCallbackFunction) {


//     const options = {
//         method: 'POST',
//         body: data
//     };

//     fetch(webServiceUrl, options)
//         .then((response) => response.json())
//         .then(function (data) {

//             SuccessCallbackFunction(data);
//         })
//         .catch(function (error) {

//             ErrorCallbackFunction(error);
//         });
// }


export default FetchApiGet;