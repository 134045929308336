import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import '../Assets/css/LokasyonlarItem.css'
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect, Switch } from 'react-router-dom'

class LokasyonlarItems extends Component {

    // static propTypes = {
    //     lokasyon: PropTypes.array.isRequired,
    // }


    render() {

        const renkler = {
            yesil: "#43a047",
            turuncu: "#FFA500",
            kirmizi: "#D50000"

            //    mavi: "#007bff",
            //    turuncu: "#FFA800",
            //    kirmizi: ""           
        }


        return (
            <div className="lokasyonlarItemRow">
                {
                    this.props.lokasyonList.map(lokasyon =>

                        <div key={lokasyon.LokasyonID} className="col-xl-4">
                            {/*begin::Stats Widget 23 --- card card-custom bg-info card-stretch gutter-b*/}
                            {/* == yerine && operatörü kulanılanılıyor */}
                            <div style={{
                                backgroundColor: (lokasyon.Renk) === "Green" && renkler.yesil
                                    || (lokasyon.Renk) === "Orange" && renkler.turuncu
                                    || (lokasyon.Renk) === "Red" && renkler.kirmizi
                            }}
                                className="card card-custom card-stretch gutter-b">
                                {/*begin::Body*/}
                                {/* {`${"/LokasyonDetay/"}${lokasyon.LokasyonID}`} url e paramete göndermek için bu da kullanılabilir */}
                                <Link to={"/LokasyonDetay/" + lokasyon.LokasyonID} className="card-body my-4">
                                    <a className="card-title font-weight-bolder text-white font-size-h2 mr-2 text-hover-state-dark d-block">{lokasyon.Lokasyon}</a>
                                    {/* <div className="font-weight-bold text-white font-size-sm"><span className="font-size-h2 mr-2">87K%</span>23k to goal</div> */}
                                    <div className="progress progress-xs mt-7  bg-white-o-90">
                                        <div className="progress-bar bg-white" role="progressbar" style={{ width: '100%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                                    </div>
                        <div style={{ marginTop: "27px" }} className="font-weight-bold text-white font-size-sm"><span className="font-size-h2 mr-2">{lokasyon.Zaman}</span></div>
                                </Link>
                                {/*end::Body*/}
                            </div>
                            {/*end::Stats Widget 23*/}
                        </div>
                    )
                }
            </div>
        );
    }
}

export default LokasyonlarItems;