import React, { Component } from 'react';
import {
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Image,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react'

class Footer extends Component {
    render() {
        return (
            <div>
                <Segment inverted vertical style={{ margin: '0em 0em 0em', padding: '3em 0em', background: "#3699FF" }}>
                    <Container textAlign='center' style={{ marginLeft: "auto", marginRight: "auto", color: "#FFFFFF" }}>                    
                        
                        {/* <Grid divided inverted stackable>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='Group 1' />
                                <List link inverted>
                                    <List.Item as='a'>Link One</List.Item>
                                    <List.Item as='a'>Link Two</List.Item>
                                    <List.Item as='a'>Link Three</List.Item>
                                    <List.Item as='a'>Link Four</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='Group 2' />
                                <List link inverted>
                                    <List.Item as='a'>Link One</List.Item>
                                    <List.Item as='a'>Link Two</List.Item>
                                    <List.Item as='a'>Link Three</List.Item>
                                    <List.Item as='a'>Link Four</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <Header inverted as='h4' content='Group 3' />
                                <List link inverted>
                                    <List.Item as='a'>Link One</List.Item>
                                    <List.Item as='a'>Link Two</List.Item>
                                    <List.Item as='a'>Link Three</List.Item>
                                    <List.Item as='a'>Link Four</List.Item>
                                </List>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <Header inverted as='h4' content='Footer Header' />
                                <p>
                                    Extra space for a call to action inside the footer that could help re-engage users.
                    </p>
                            </Grid.Column>
                        </Grid> */}

                        <Divider inverted section />
                        <Image centered src={require("../Assets/images/Teknolab_Logo_64.png")} />
                        <h1>Teknolab Yazılım</h1>
                        {/* <List horizontal inverted divided link >
                            <List.Item as='a' href='#'>
                                Site Map
                  </List.Item>
                            <List.Item as='a' href='#'>
                                Contact Us
                  </List.Item>
                            <List.Item as='a' href='#'>
                                Terms and Conditions
                  </List.Item>
                            <List.Item as='a' href='#'>
                                Privacy Policy
                  </List.Item>
                        </List> */}
                    </Container>
                </Segment>
            </div>
        );
    }
}

export default Footer;