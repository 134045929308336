import React, { Component } from 'react';

class HataSayfasi extends Component {
    render() {
        return (
            <div>
              <h1>Sayfa Bulunamadı</h1>  
            </div>
        );
    }
}

export default HataSayfasi;