import React, { Component } from 'react';
import PageHeader from './PageHeader';
import LokasyonlarItems from './LokasyonlarItems';
import * as FetchApi from './Fetch';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect, Switch } from 'react-router-dom'

//import 'semantic-ui-css/semantic.min.css';
import {
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Image,
    List,
    Menu,
    Segment,
} from 'semantic-ui-react'

// import '../Assets/css/Lokasyonlar.css'
// import '../Assets/css/LokasyonlarItem.css'


class Lokasyonlar extends Component {

    constructor(props) {
        super(props);
        this.lokasyonariGetir = this.lokasyonariGetir.bind(this);
        this.succeslokasyonariGetir = this.succeslokasyonariGetir.bind(this);
        this.errorlokasyonariGetir = this.errorlokasyonariGetir.bind(this);
        this.tick = this.tick.bind(this);    

        // const token = sessionStorage.getItem('Token');
        // console.log(token);

        // if( token === null){

        //     this.props.history.push("/");
        // }
    }

    state = {
        lokasyonList: [],
        saniye: 60
    }

    componentDidMount() {
        this.lokasyonariGetir();
        this.timer = setInterval(this.tick, 1000);
    }

    lokasyonariGetir() {
        const clientid = "clientid";
        FetchApi.FetchApiGet("Lokasyon/LokasyonList", clientid, this.succeslokasyonariGetir, this.errorlokasyonariGetir)
    }
    succeslokasyonariGetir(lokasyonList) {
        // console.log(lokasyonList);
        this.setState({
            lokasyonList: lokasyonList
        })
    }

    errorlokasyonariGetir(error) {
        console.log(error);
    }

    tick(){
        if (this.state.saniye > 0) {
          this.setState({saniye: this.state.saniye - 1})
        } else {
          clearInterval(this.timer);
          this.setState({saniye: 60});
          this.timer = setInterval(this.tick, 1000);
          this.lokasyonariGetir();
          //window.location.reload();
        }
      }


    render() {
        const footerMarginTop = this.state.lokasyonList.length < 5 ? "140px" : "0px";
        return (
            
            <div>
                <PageHeader/>
                
                <LokasyonlarItems lokasyonList={this.state.lokasyonList} />            

                <Segment inverted vertical 
                 style={{ margin: '0em 0em 0em', marginTop: footerMarginTop, padding: '3em 0em', background: "#3699FF" }}>
                    <Container textAlign='center' style={{ marginLeft: "auto", marginRight: "auto", color: "#FFFFFF" }}>
                    <h1>{this.state.saniye}</h1>
                                            
                        <Divider inverted section />
                        <Image centered src={require("../Assets/images/Teknolab_Logo_64.png")} />
                        <h1>Teknolab Yazılım</h1>
                    </Container>
                </Segment>
            </div>
        );
    }
}




export default Lokasyonlar