import React, { Component } from 'react';
import * as FetchApi from './Fetch';
import PageHeader from './PageHeader';
import Footer from "./Footer";
import {
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Image,
    List,
    Menu,
    Segment,
    Table,
    Icon
} from 'semantic-ui-react'

class LokasyonDetay extends Component {

    constructor(props) {
        super(props);
        this.lokasyonDetayiniGetir = this.lokasyonDetayiniGetir.bind(this);
        this.succeslokasyonDetayiniGetir = this.succeslokasyonDetayiniGetir.bind(this);
        this.errorlokasyonDetayiniGetir = this.errorlokasyonDetayiniGetir.bind(this);
    }

    state = {
        LokasyonModel: {},
        LokasyonDetayList: []
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.lokasyonDetayiniGetir();
    }

    lokasyonDetayiniGetir() {

        const lokasyonID = this.props.match.params.lokasyonID
        const data = { lokasyonID: lokasyonID };
        FetchApi.FetchApiGet("Lokasyon/LokasyonDetay", data, this.succeslokasyonDetayiniGetir, this.errorlokasyonDetayiniGetir)
    }

    succeslokasyonDetayiniGetir(lokasyonModel) {
        //console.log(lokasyonModel);
        this.setState({
            LokasyonModel: lokasyonModel,
            LokasyonDetayList: lokasyonModel.DetayList
        })
    }

    errorlokasyonDetayiniGetir(error) {
        console.log(error);
    }

    render() {
        return (
            <div>
                <PageHeader />
                {/* LokayonDetay Sayfası LokasyonID :  { this.props.match.params.lokasyonID } */}

                <div style={{ marginTop: '7em', marginLeft: "38px", marginRight: "26px", marginBottom: "7em" }}>
                    <Header as='h1'>{this.state.LokasyonModel.Sehir} / {this.state.LokasyonModel.Lokasyon}</Header>
                    {/* <Header as='h1'>{this.state.LokasyonModel.Lokasyon}</Header> */}
                    {/* <Header as='h1'>{this.state.LokasyonDetayList[1].Parametre}</Header> */}
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>ATM Parametresi</Table.HeaderCell>
                                <Table.HeaderCell>ATM Durumu</Table.HeaderCell>
                                {/* <Table.HeaderCell>Notes</Table.HeaderCell> */}
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>

                            {
                                this.state.LokasyonDetayList.map(lokasyonDetay =>

                                    <Table.Row>
                                        {(lokasyonDetay.Renk) === "Red" && <Table.Cell style={{ background: "#D50000", color: "White" }}>{lokasyonDetay.Parametre}</Table.Cell>
                                            || (lokasyonDetay.Renk) === "Orange" && <Table.Cell style={{ background: "#FFA500", color: "White" }}>{lokasyonDetay.Parametre}</Table.Cell>
                                            || (lokasyonDetay.Renk) === "White" && <Table.Cell>{lokasyonDetay.Parametre}</Table.Cell>}

                                        {/* <Table.Cell>{lokasyonDetay.Parametre}</Table.Cell> */}
                                        {(lokasyonDetay.Renk) === "Red" && <Table.Cell style={{ background: "#D50000", color: "White" }}>{lokasyonDetay.Deger}</Table.Cell>
                                            || (lokasyonDetay.Renk) === "Orange" && <Table.Cell style={{ background: "#FFA500", color: "White" }}>{lokasyonDetay.Deger}</Table.Cell>
                                            || (lokasyonDetay.Renk) === "White" && <Table.Cell>{lokasyonDetay.Deger}</Table.Cell>}

                                        {/* <Table.Cell>{lokasyonDetay.Deger}</Table.Cell> */}
                                        {/* <Table.Cell negative>None</Table.Cell> */}
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
                
                <Footer />
            </div>
        );
    }
}

export default LokasyonDetay;