import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    Button,
    Container,
    Dropdown,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link, NavLink, Redirect, Switch } from 'react-router-dom'


const getWidth = () => {
    const isSSR = typeof window === 'undefined'

    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


const HomepageHeading = ({ mobile }) => (
    <Container text>
        {/* <Header
      as='h1'
      content='Imagine-a-Company'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
      }}
    /> */}

        <div className="d-flex flex-center" style={{ marginTop: "10px" }}>
            <a
            //  style={{ marginTop: "94px" }}
            >
                <img className="Teknolab-Logo" src={require("../Assets/images/Teknolab_Logo.png")} alt="Teknolab Logo" />
            </a>
        </div>
        {/* <div style={{ marginBottom: "2.50rem" }}>
            <h2 style={{ color: "#3699ff" }}>Teknolab Yazılım</h2>
        </div> */}
        {/* <Header
      as='h2'
      content='Do whatever you want when you want to.'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.5em',
      }}
    />
    <Button primary size='huge'>
      Get Started
      <Icon name='right arrow' />
    </Button> */}
    </Container>
)

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
}

// class DesktopContainer extends Component {
//     state = {}

//     hideFixedMenu = () => this.setState({ fixed: false })
//     showFixedMenu = () => this.setState({ fixed: true })

//     render() {
//         const { children } = this.props
//         const { fixed } = this.state

//         return (
//             <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
//                 <Visibility
//                     once={false}
//                     onBottomPassed={this.showFixedMenu}
//                     onBottomPassedReverse={this.hideFixedMenu}
//                 >

//                     <Menu
//                         fixed='top' inverted style={{ background: "#3699FF" }}

//                     >
//                         <Container style={{ marginLeft: "38px" }}>
//                             <Menu.Item as={Link} to="/Lokasyonlar" header>
//                                 <Image size='mini' src={require("../Assets/images/Teknolab_Logo_64.png")} style={{ marginRight: '1.5em' }} />
//           Teknolab Yazılım
//         </Menu.Item>
//                             <Menu.Item as={Link} to="/Lokasyonlar">
//                                 Anasayfa
//                     </Menu.Item>

//                         </Container>


//                         <Menu.Menu position='right'>
//                             <Dropdown item simple text="Mustafa" style={{ marginRight: "26px" }}>
//                                 <Dropdown.Menu>

//                                     <Dropdown.Item>
//                                         <Button color='red' onClick={this.kullaniciCikisiYap} >Çıkış</Button>
//                                     </Dropdown.Item>

//                                 </Dropdown.Menu>
//                             </Dropdown>
//                         </Menu.Menu>
//                     </Menu>

//                 </Visibility>

//                 {children}
//             </Responsive>
//         )
//     }
// }

// DesktopContainer.propTypes = {
//     children: PropTypes.node,
// }

// class MobileContainer extends Component {
//     state = {}

//     handleSidebarHide = () => this.setState({ sidebarOpened: false })

//     handleToggle = () => this.setState({ sidebarOpened: true })

//     render() {
//         const { children } = this.props
//         const { sidebarOpened } = this.state

//         return (
//             <Responsive
//                 as={Sidebar.Pushable}
//                 getWidth={getWidth}
//                 maxWidth={Responsive.onlyMobile.maxWidth}
//             >
//                 <Sidebar
//                     as={Menu}
//                     animation='push'
//                     inverted
//                     onHide={this.handleSidebarHide}
//                     vertical
//                     visible={sidebarOpened}
//                     style={{ background: "#3699FF" }}
//                 >


//                     {/* <Menu.Item as={Link} to="/Lokasyonlar" header>
//                         <Image size='mini' src={require("../Assets/images/Teknolab_Logo_64.png")} style={{ marginRight: '1.5em' }} />
//           Teknolab Yazılım
//         </Menu.Item> */}
//                     <Menu.Item as={Link} to="/Lokasyonlar">
//                         Anasayfa
//                     </Menu.Item>
//                 </Sidebar>

//                 <Sidebar.Pusher dimmed={sidebarOpened}>
//                     <Segment
//                         inverted
//                         textAlign='center'
//                         style={{ minHeight: 150, padding: '1em 0em', background: "#ffffff" }}
//                         vertical
//                     >
//                         <Container>
//                             <Menu inverted pointing secondary size='large' style={{ marginTop: "-14px", background: "#3699FF", borderColor: "#3699FF" }}>
//                                 <Menu.Item onClick={this.handleToggle}>
//                                     <Icon name='sidebar' />
//                                 </Menu.Item>
//                                 <Menu.Menu position='right'>
//                                     <Dropdown item simple text="Mustafa" style={{ marginRight: "26px" }}>
//                                         <Dropdown.Menu>

//                                             <Dropdown.Item>
//                                                 <Button color='red' onClick={this.kullaniciCikisiYap} >Çıkış</Button>
//                                             </Dropdown.Item>

//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </Menu.Menu>
//                                 {/* <Menu.Item position='right'>
//                                     <Button as='a' inverted>
//                                         Log in
//                   </Button>
//                                     <Button as='a' inverted style={{ marginLeft: '0.5em' }}>
//                                         Sign Up
//                   </Button>
//                                 </Menu.Item> */}
//                             </Menu>
//                         </Container>
//                         <HomepageHeading mobile />
//                     </Segment>

//                     {children}
//                 </Sidebar.Pusher>
//             </Responsive>
//         )
//     }
// }

// MobileContainer.propTypes = {
//     children: PropTypes.node,
// }

// const ResponsiveContainer = ({ children }) => (
//     <div>
//         <DesktopContainer>{children}</DesktopContainer>
//         <MobileContainer>{children}</MobileContainer>
//     </div>
// )

// ResponsiveContainer.propTypes = {
//     children: PropTypes.node,
// }



class PageHeader extends Component {

    constructor(props) {
        super(props);
        this.kullaniciCikisiYap = this.kullaniciCikisiYap.bind(this);
    }

    state = {
        kullaniciAdi: ""
    }

    kullaniciCikisiYap() {
        sessionStorage.removeItem('Token');
        sessionStorage.removeItem('KullaniciAdi');
        this.props.history.push("/");
        //const { history } = this.props;
        //history.push('/', { some: 'state' });
        // history.push("/");
        //this.props.history.push("/");
        //return <Redirect to='/'  />
    }

    componentDidMount() {
        this.setState({
            kullaniciAdi: sessionStorage.getItem('KullaniciAdi')
        });
    }

    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })

    handleSidebarHide = () => this.setState({ sidebarOpened: false })
    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {

        const { children } = this.props
        const { fixed } = this.state

        // const { children } = this.props
        const { sidebarOpened } = this.state

        return (
            // <div>
            //     <ResponsiveContainer/>
            // </div>

            // Masaüstü Header
            <div>
                <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
                    <Visibility
                        once={false}
                        onBottomPassed={this.showFixedMenu}
                        onBottomPassedReverse={this.hideFixedMenu}
                    >

                        <Menu
                            fixed='top' inverted style={{ background: "#3699FF" }}

                        >
                            <Container style={{ marginLeft: "38px" }}>
                                <Menu.Item as={Link} to="/Lokasyonlar" header>
                                    <Image size='mini' src={require("../Assets/images/Teknolab_Logo_64.png")} style={{ marginRight: '1.5em' }} />
          Teknolab Yazılım
        </Menu.Item>
                                <Menu.Item as={Link} to="/Lokasyonlar">
                                    Anasayfa
                    </Menu.Item>
                            </Container>


                            <Menu.Menu position='right'>
                                <Dropdown item simple text={this.state.kullaniciAdi} style={{ marginRight: "26px" }}>
                                    <Dropdown.Menu>

                                        <Dropdown.Item>
                                            <Button color='red' onClick={this.kullaniciCikisiYap} >Çıkış</Button>
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        </Menu>

                    </Visibility>

                    {children}
                </Responsive>

                {/* Mobile Header */}
                <Responsive
                    as={Sidebar.Pushable}
                    getWidth={getWidth}
                    maxWidth={Responsive.onlyMobile.maxWidth}
                >
                    <Sidebar
                        as={Menu}
                        animation='push'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                        style={{ background: "#3699FF" }}
                    >


                        {/* <Menu.Item as={Link} to="/Lokasyonlar" header>
        <Image size='mini' src={require("../Assets/images/Teknolab_Logo_64.png")} style={{ marginRight: '1.5em' }} />
Teknolab Yazılım
</Menu.Item> */}
                        <Menu.Item as={Link} to="/Lokasyonlar">
                            Anasayfa
    </Menu.Item>
                        <Menu.Item as="a" onClick={this.kullaniciCikisiYap} >
                            Çıkış Yap
                    </Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            inverted
                            textAlign='center'
                            style={{ minHeight: 150, padding: '1em 0em', background: "#ffffff" }}
                            vertical
                        >
                            <Container>
                                <Menu inverted pointing secondary size='large' style={{ marginTop: "-14px", background: "#3699FF", borderColor: "#3699FF" }}>
                                    <Menu.Item onClick={this.handleToggle}>
                                        <Icon name='sidebar' />
                                    </Menu.Item>
                                    <Menu.Menu position='right'>
                                        <Dropdown item simple text={this.state.kullaniciAdi} style={{ marginRight: "26px" }}>
                                            <Dropdown.Menu>

                                                <Dropdown.Item>
                                                    <Button color='red' onClick={this.kullaniciCikisiYap} >Çıkış</Button>
                                                </Dropdown.Item>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Menu.Menu>
                                    {/* <Menu.Item position='right'>
                    <Button as='a' inverted>
                        Log in
  </Button>
                    <Button as='a' inverted style={{ marginLeft: '0.5em' }}>
                        Sign Up
  </Button>
                </Menu.Item> */}
                                </Menu>
                            </Container>
                            <HomepageHeading mobile />
                        </Segment>

                        {children}
                    </Sidebar.Pusher>
                </Responsive>
            </div>
        );
    }
}


// const PageHeader = () => (
//     <ResponsiveContainer>

//     </ResponsiveContainer>
// )

// export default HomepageLayout

export default withRouter(PageHeader);