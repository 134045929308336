import React, { Component } from 'react';
import * as FetchApi from './Fetch';
import '../Assets/css/Login.css'
// import BackgroundImage from '../Assets/images/login_background.jpg';
// import { BrowserRouter as Router, Route, Link, NavLink, Redirect, Switch } from 'react-router-dom';
import swal from 'sweetalert';

class Login extends Component {

    constructor(props) {
        super(props);

        this.kullaniciGirisiYap = this.kullaniciGirisiYap.bind(this);
        this.textChange = this.textChange.bind(this);
        this.lokasyonlarSayfasinaGit = this.lokasyonlarSayfasinaGit.bind(this);
        this.successKullaniciGirisiYap = this.successKullaniciGirisiYap.bind(this);
        this.errorKullaniciGirisiYap = this.errorKullaniciGirisiYap.bind(this);
        this.enteraBasildi = this.enteraBasildi.bind(this);

        sessionStorage.removeItem('Token');
        sessionStorage.removeItem('KullaniciAdi');
    }

    state = {
        kullaniciAdi: "",
        kullaniciSifresi: "",
    }

    componentDidMount() {
        this.kullaniciAdiInput.focus();
    }

    textChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    kullaniciGirisiYap() {
        console.log("KullaniciGirisiYap basıldı");
        //this.lokasyonlarSayfasinaGit();
        // return  <Redirect to="/Lokasyonlar"/>;

        const user = {
            UserName: this.state.kullaniciAdi,
            Password: this.state.kullaniciSifresi
        }

        const webServiceMethod = "Login/TokenGetir";

        FetchApi.FetchApiGet(webServiceMethod, user, this.successKullaniciGirisiYap, this.errorKullaniciGirisiYap);
    }

    enteraBasildi(e) {
        //console.log("Enter Metoduna geldi");
        if (e.key == "Enter") {
            //console.log("Entera Basıldı");
            this.kullaniciGirisiYap();
        }
    }

    successKullaniciGirisiYap(kullaniciGirisiResultModel) {
        //console.log(kullaniciGirisiResultModel);

        if (kullaniciGirisiResultModel.KullaniciGirisiBasariliMi) {
            sessionStorage.setItem('Token', kullaniciGirisiResultModel.Token);
            sessionStorage.setItem('KullaniciAdi', kullaniciGirisiResultModel.KullaniciAdi);
            this.lokasyonlarSayfasinaGit(kullaniciGirisiResultModel);
        }
        else {
            // Kullanıcı Girişi Başarısız
            swal("Kullanıcı Girişi Başarısız", "Lütfen Kullanıcı Adınızı ve Şifrenizi Doğru Bir Şekilde Yazınız", "warning")
                .then((value) => {
                    this.kullaniciAdiInput.focus();
                });
        }

    }

    errorKullaniciGirisiYap(error) {
        console.log(error);
    }

    lokasyonlarSayfasinaGit(kullaniciGirisiResultModel) {
        // console.log("lokasyonlarSayfasinaGit");
        // return <Redirect to="/Lokasyonlar" />;            
        this.props.history.push("/Lokasyonlar");
    }

    render() {
        return (
            <div>
                {/*begin::Main*/}
                <div className="d-flex flex-column flex-root">
                    {/*begin::Login*/}
                    <div className="login login-4 login-signin-on d-flex flex-row-fluid" id="kt_login">
                        <div className="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat"
                        //../Assets/images/login_background.jpg" 
                        //  style={{ backgroundImage: "url(" + BackgroundImage + ")" }}
                        // style={{ backgroundImage: 'url(' + require('../Assets/images/login_background.jpg') + ')'                        
                         style={{ backgroundColor: "#ffffff" }}                         
                         >
                            <div className="login-form text-center p-7 position-relative overflow-hidden">
                                {/*begin::Login Header*/}
                                <div className="d-flex flex-center mb-10">
                                    <a>
                                        {/* <img src="https://keenthemes.com/metronic/themes/metronic/theme/html/demo7/dist/assets/media/logos/logo-letter-13.png" className="max-h-75px" alt="" /> */}
                                        <img className="Teknolab-Logo" src={require("../Assets/images/Teknolab_Logo.png")} alt="Teknolab Logo" />
                                    </a>
                                </div>
                                {/*end::Login Header*/}
                                {/*begin::Login Sign in form*/}
                                <div className="login-signin">
                                    {/* className="mb-15" */}
                                    <div style={{ marginBottom: "2.50rem" }}>
                                        <h2 style={{ color: "#3699ff" }}>Teknolab Yazılım</h2>
                                        {/* <div className="text-muted font-weight-bold">Enter your details to login to your account:</div> */}
                                    </div>
                                    <div className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_signin_form" noValidate="novalidate">
                                        <div className="form-group mb-5 fv-plugins-icon-container">
                                            <input className="form-control h-auto form-control-solid py-4 px-8" ref={(input) => { this.kullaniciAdiInput = input; }}
                                                type="text" placeholder="Kullanıcı Adı" name="kullaniciAdi" id="kullaniciAdi" onChange={this.textChange} autoComplete="off" />
                                            <div className="fv-plugins-message-container" /></div>
                                        <div className="form-group mb-5 fv-plugins-icon-container">
                                            <input className="form-control h-auto form-control-solid py-4 px-8"
                                                onKeyPress={this.enteraBasildi} type="password" placeholder="Şifre" name="kullaniciSifresi" id="kullaniciSifresi" onChange={this.textChange} />
                                            <div className="fv-plugins-message-container" /></div>
                                        {/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                            <label className="checkbox m-0 text-muted">
                                                <input type="checkbox" name="remember" /> Remember me
                            <span />
                                            </label>
                                            <a href="javascript:;" id="kt_login_forgot" className="text-muted text-hover-primary">Forget Password ?</a>
                                        </div> */}
                                        <button id="kt_login_signin_submit" onClick={this.kullaniciGirisiYap} className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Giriş</button>
                                        <input type="hidden" /><div /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Login*/}
                </div>
            </div>
        );
    }
}

export default Login;