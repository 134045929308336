import React, { Component } from 'react';
// import logo from './logo.svg';
//import './Assets/Css/App.css';
import { BrowserRouter as Router, HashRouter, Route, Link, NavLink, Redirect, Switch } from 'react-router-dom'
import Login from './Components/Login';
import Lokasyonlar from './Components/Lokasyonlar';
import LokasyonDetay from './Components/LokasyonDetay';
import HataSayfasi from "./Components/HataSayfasi";
import Deneme from "./Components/Deneme";


class App extends Component {

  state = {
    loggedIn: false,
  }

  render() {

    return (
      <div>
        <HashRouter>
          <div>
            {/* <Login /> */}
            <Switch>
              <Route path="/" exact strict component={Login} />
              <Route path="/Lokasyonlar" exact strict render={() => (
                sessionStorage.getItem('Token') !== null ? (<Lokasyonlar />) : (<Redirect to="/" />)
              )} />
              <Route path="/LokasyonDetay/:lokasyonID" exact strict render={(props) => (
                sessionStorage.getItem('Token') !== null ? (<LokasyonDetay {...props} />) : (<Redirect to="/" />)
              )} />
              <Route path="/Deneme" exact strict component={Deneme} />
              <Route component={HataSayfasi} />
            </Switch>
          </div>
        </HashRouter>

      </div>
    );
  }
}


// class App extends Component {
//   render() {
//     return (


//       <Router>     


//       <div>
//         <Login/>
//       </div>


//       <Switch>
//         <Route path = "/" exact strict component={Login}/> 
//         <Route path = "/Lokasyonlar" exact strict component={Lokasyonlar}/> 
//         <Route component={HataSayfasi}/>                         
//       </Switch>     
//     </Router>
//     );
//   }
// }

export default App;
